// eslint-disable-next-line no-redeclare,no-unused-vars
function isReadMode(row, param) {
  var readMode = false;
  var read = getParamSettings(param, 'read');
  if (read) {
    if (isPlainObject(read)) {
      // $.each(read, function (roll, val) {
      //   if (strpos(roll, '!') === 0 && role.slice(1) != role) read[role] = val;
      // });
      // if (read[role] && row[read[role]] != user[read[role]]) readMode = true;
      if (checkWhere(row, read, {object: param.object, nullAsEmpty: true}))
        readMode = true;
    } else if (isArray(read)) {
      if (inArray(role, read)) {
        readMode = true;
      } else
        $.each(read, function (i, k) {
          if (strpos(k, '!') === 0) {
            if (k.slice(1) == role) {
              readMode = false;
              return false;
            } else readMode = true;
          }
          if (getObjectValue(fields, [param.object, k, 'disable']))
            $.each(fields[param.object][k].disable, function (val, fie) {
              if (checkWhereValue(param.object, row, k, val))
                param.readFields = fie;
            });
        });
    } else readMode = true;
  }
  if (row.read && row.id) readMode = true;
  return readMode;
}
